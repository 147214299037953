export const PurposeOfThisPolicy = [
  {
    id: "1",
    point:
      "This Privacy and Data Protection Policy sets out the practices that SKOOL BUS PTE LTD (“we”, “our”, “us”, “Company”) follows with respect to the collection, use maintenance, and disclosure of information collected via SkoolBus Application.",
  },
  {
    id: "2",
    point:
      "We respect and value the privacy of each of you who enter SkoolBus Application, and we commit towards maintaining the highest standards required in order to protect any personal information you consent to share with us in accordance with the Singapore’s Personal Data Protection Act 2012 (the “PDPA”). This Privacy Policy applies only to information we collect from the SkoolBus Application and does not apply to any other application, website or business activity of the Company.",
  },
  {
    id: "3",
    point:
      "Personal Data means any data about an individual who can be identified either from the data itself or from other data that the Company is likely to have access to. The purpose of this policy is to provide you with an understanding of how we collect, handle, and use your personal data.",
  },
  {
    id: "4",
    point:
      "We reserve the right to revise this Privacy Policy at any time, without prior notice, and the changes will be effective subsequent to posting the changes to the SkoolBus Application. Please check SkoolBus Application frequently to be updated on the changes. By accessing the content and services made available on SkoolBus Application and by providing your personal data to us, you acknowledge and agree that you have fully read and understood this policy, and consent to the collection, use, processing, and disclosure of your personal data as described in this policy.",
  },
];

export const AsADriver = [
  {
    id: "1",
    point: "Full name of the primary driver",
  },
  {
    id: "2",
    point: "Contact number of the primary driver",
  },
  {
    id: "3",
    point: "Residential address of the primary driver",
  },
  {
    id: "4",
    point: "License number of the primary driver",
  },
  {
    id: "5",
    point: "Primary driver’s vehicle information",
  },
  {
    id: "6",
    point: "Primary driver’s journey start/end time",
  },
  {
    id: "7",
    point: "Primary driver’s journey start location",
  },
  {
    id: "8",
    point: "Full name of the secondary driver",
  },
  {
    id: "9",
    point: "Contact number of the secondary driver",
  },
  {
    id: "10",
    point: "License number of the secondary driver",
  },
  {
    id: "11",
    point: "Secondary driver’s vehicle information",
  },
  {
    id: "12",
    point: "Secondary driver’s journey start/end time",
  },
  {
    id: "13",
    point: "Secondary driver’s journey start location",
  },
];

export const AsAParent = [
  {
    id: "1",
    point: "Full name and residential address of the parent/guardian ",
  },
  {
    id: "2",
    point: "Full name of the child",
  },
  {
    id: "3",
    point: "Date of birth of the child",
  },
  {
    id: "4",
    point: "Contact number of the parent/guardian",
  },
  {
    id: "5",
    point: "Full name of the nominated guardian",
  },
  {
    id: "6",
    point: "Contact number of the nominated guardian",
  },
  {
    id: "7",
    point: "Child’s School details including name, address, grade etc.",
  },
  {
    id: "8",
    point: "Child’s availability for the journeys",
  },
  {
    id: "9",
    point: "Medical information of the child",
  },
];

export const UsageOfInformation = [
  {
    id: "1",
    point:
      "Provide and maintain the Skoolbus Application- To process and fulfill your purchases and subscriptions, send you confirmation emails of your purchase and subscription status and allow you to pay for subscriptions.",
  },
  {
    id: "2",
    point:
      "Improve, personalize, and develop the Skoolbus Application -We use the information collected to; improve and personalize customer experience, perform data analysis and testing, collect feedback to help improve our programs, features or/and services, produce certification, enhance the online experience of using the Skoolbus Application.",
  },
  {
    id: "3",
    point:
      "Communicate with you-To alert you about products upgrades and revisions, respond to you when you contact us, and promote new features or products that we feel may be of interest you to assist in addressing your inquiries and troubleshooting, about our products and services. To provide you with information that is relevant to your use of the service.",
  },
];

export const DiscloseOfInformation = [
  {
    id: "1",
    point:
      "We do not sell, trade or otherwise transfer to third parties your personally identifiable information. Any information provided to the Skoolbus Application will be protected from loss, misuse, unauthorized access or disclosure, alteration or destruction. However, we share your personal information with authorized third parties who assist us in operating the Skoolbus Application, conducting our business or servicing you including, but not limited to, credit card payments and online support, as long as these parties are compliant with applicable data protection laws. ",
  },
  {
    id: "2",
    point:
      "We use Firebase, a service provided by Google, for data storage and phone authentication. Your data may be processed and stored by Firebase in accordance with their privacy policy.",
  },
  {
    id: "3",
    point:
      "We may choose to retain your personal information to the full extent of any period provided by law and statute. We may also choose to delete your personal information from our databases after a required number of years and/or if the intended purposes for which we have collected your information is completed or is no longer relevant. We may release your information when we are required to comply with the law, enforce our policies, or protect Company’s and other third-party rights, property or safety. However, non-personally identifiable information may be provided by the Company to third parties for marketing, advertising, or other uses.",
  },
];

export const StorageAndRetention = [
  {
    id: "2",
    point:
      "Obtaining regular backup for the SkoolBus Application data and testing the backup and recovery procedures.",
  },
  {
    id: "3",
    point:
      "Monitoring and logging access (only metadata), network traffic, and system activities for detection of security incidents and getting monitored via Security Operation Centre.",
  },
  {
    id: "4",
    point:
      "Classified data based on sensitivity and applied appropriate security controls over the Risk Based Authentication.",
  },
];

export const AssessmentAndProtection = [
  {
    id: "1",
    point:
      "Implement strong access controls including input validation and multifactor authentication.",
  },
  {
    id: "2",
    point:
      "Use secure protocols (SSL/TLS version 1.2 and higher) for data transmission and avoid transmitting sensitive data over unsecured networks.",
  },
  {
    id: "3",
    point:
      "Establish clear data retention policies and securely dispose of data that is no longer needed.",
  },
  {
    id: "4",
    point:
      "The Company is keeping systems and software up to date with the latest security patches.",
  },
  {
    id: "5",
    point:
      "Conduct regular security audits, GDPR compliances and assessments to identify vulnerabilities and implement necessary measures.",
  },
  {
    id: "6",
    point:
      "Encrypted the offline data stored on the device using strong encryption algorithms. Android provides cryptographic APIs and libraries (AES)",
  },
  {
    id: "7",
    point:
      "Established secure key management techniques to store and handle encryption keys (API keys).",
  },
  {
    id: "8",
    point: "Data protection by design and default",
  },
];

export const AssessmentAndProtectionSubPoints = [
  {
    id: "1",
    point:
      "Monitoring and reporting in a file any unauthorized or illegal access attempts",
  },
  {
    id: "2",
    point:
      "Monitoring specific activities such as who accesses personal data and with whom the data are being shared.",
  },
  {
    id: "3",
    point:
      "Keeping a record of how long the data are to be stored while being stored.",
  },
  {
    id: "4",
    point:
      "The data are encrypted, pseudonymized, and anonymized whenever possible, to protect them from any unauthorized access.",
  },
  {
    id: "5",
    point:
      "The components are following industry standards GDPR and acquiring a security certification",
  },
];

export const Rights = [
  {
    id: "1",
    MainPoint: "Right of access",
    point:
      "You may have the right to obtain from us confirmation as to whether personal data concerning you is processed, and, where that is the case, to request access to the personal data. ",
  },
  {
    id: "2",
    MainPoint: "Right to rectification",
    point:
      "You may have the right to obtain from us the rectification of inaccurate personal data concerning you. Depending on the purposes of the processing, you may have the right to have incomplete personal data completed, including by means of providing a supplementary statement. ",
  },
  {
    id: "3",
    MainPoint: "Right to object",
    point:
      "If the processing of your personal data is based on legitimate interests, you may have the right to object, on grounds relating to your particular situation, at any time to the processing of your personal data by us and we can be required to no longer process your personal data. Moreover, if your personal data is processed for direct marketing purposes, you have the right to object at any time to the processing of personal data concerning you for such marketing, which includes profiling to the extent that it is related to such direct marketing. In this case, your personal data will no longer be processed for such purposes by us.",
  },
  {
    id: "4",
    MainPoint: "Right to fair treatment",
    point:
      "We will not discriminate against you for exercising any of your privacy rights. Irrespective of your standing on your privacy preferences, we will provide the services.",
  },
  {
    id: "5",
    MainPoint: "To restrict the use of your information",
    point: "You can request to restrict the use of your information.",
  },
  {
    id: "6",
    MainPoint: "To complain to a supervisory authority ",
    point:
      "If you have any complaints regarding the data collected and usage of it, you have the right to lodge a complaint to a superior authority.",
  },
];

export const PrivacyPolicyPoints = [
  {
    id: "1",
    ponit:
      "impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with the Company or any other person or entity",
  },

  {
    id: "2",
    ponit:
      "upload, post or otherwise transmit to or distribute or otherwise publish through the Portal any materials anonymously or under a false name",
  },
  {
    id: "3",
    ponit:
      "upload, post or otherwise transmit any content that you do not have a right to transmit under any law or under contractual duties",
  },
  {
    id: "4",
    ponit:
      "violate, plagiarize or infringe the rights of third parties, including, without limitation upload, post or otherwise transmit any content that infringes any patent, trademark, trade secret, copyright or other proprietary rights of any part",
  },
  {
    id: "5",
    ponit:
      "upload, post or otherwise transmit any material that contains software viruses, worm, Trojan horse or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment",
  },
  {
    id: "6",
    ponit:
      "interfere with or disrupt the Portal or servers or networks connected to the Services, or disobey any requirements, procedures, policies or regulations of networks connected or applicable to the Services",
  },
  {
    id: "7",
    ponit:
      "violate through use of the Portal any applicable local or international law",
  },
  {
    id: "8",
    ponit:
      "“stalk” or otherwise harass another person or user of the Portal, or take any action invasive to another’s privacy without permission from such other person",
  },
  {
    id: "9",
    ponit:
      "violate through use of the Portal any applicable local, state, national or international law",
  },
  {
    id: "10",
    ponit:
      "“stalk” or otherwise harass another person or user of the Portal, or take any action invasive to another’s privacy without permission from such other person",
  },
  {
    id: "11",
    ponit:
      "Delete, modify or revise any information of other students or drivers",
  },
  {
    id: "12",
    ponit:
      "upload any material that contains advertising of any kind, including unsolicited email advertisements, chain letters, pyramid schemes or like documents, opinions or notices commercial or otherwise or containing any information that is presented in a manner for any other purpose than that which is intended for the Portal and is acceptable by the Company. promote or provide instructional information about illegal activities, promote physical harm or injury against any government, legal entity, group or individual",
  },
  {
    id: "13",
    ponit:
      "solicit passwords or personal identifying information from other users for unintended, commercial or unlawful purposes.",
  },
  {
    id: "14",
    ponit:
      "use any robot, spider, other automatic device, or manual process to monitor or copy the Sites or their contents, or any substantial part thereof without our prior written consent.",
  },
  {
    id: "15",
    ponit:
      "exceed the scope of the Portal you have signed up for, for example, accessing and using non-public portions of the application, groups or features that you do not have a right to use, or deleting, adding to or otherwise changing another person’s entries or other Content when you have not been granted the right to do so.",
  },
  {
    id: "16",
    ponit:
      "access (or attempt to access) the Portal by any means other than through the interface(s) provided by the Company, or access (or attempt to access) any of the Services through any automated means (including use of scripts, web crawlers or the like) or otherwise engage the Services in a manner reasonably likely to be harmful to the systems operating the Portal or the access or use of the Portal by others",
  },
  {
    id: "17",
    ponit:
      "reproduce, duplicate, copy, sell, trade, resell or exploit for any commercial purposes, any portion or use of, or access to, the Services",
  },
  {
    id: "18",
    ponit:
      "you further represent and warrant NOT to upload or display publicly Content that: ",
  },
];

export const TermsNConditionsGeneral = [
  {
    id: "1",
    MainPoint: "Changes to Terms and Conditions of Use: ",
    point:
      "The Company reserves the right to modify the terms, conditions, and notices under which the Service is offered.  Your continued use of the Portal and the Service after the postings of any amended terms and conditions shall constitute your agreement to be bound by any such changes.  ",
  },
  {
    id: "2",
    MainPoint: "Entire Agreement:",
    point:
      "This Agreement is the entire Agreement between the Company and you regarding the use of Portal and the Service and all changes must be in writing as an amendment to this document.  Verbal or other communications are not valid until written in this document.   ",
  },
  {
    id: "3",
    MainPoint: "Governing Law: ",
    point:
      "This Agreement shall be governed by and construed in strict compliance with the relevant laws and regulations established by the government of Singapore. ",
  },
  {
    id: "4",
    MainPoint: "Arbitration:",
    point:
      " Any dispute, controversy or claims arising out of this Agreement or breach, termination or invalidity thereto between the parties shall first be resolved amicably via negotiations. Any dispute that cannot be resolved via negotiations, shall be referred to and finally resolved by arbitration administered by the Singapore International Arbitration Centre (“SIAC”) in accordance with the Arbitration Rules of the Singapore International Arbitration Centre (“SIAC Rules”) for the time being in force, which rules are deemed to be incorporated by reference in this clause. The language shall be English, and the Arbitral seat shall be Singapore. ",
  },
  {
    id: "5",
    MainPoint: "Compliance with Applicable Laws",
    point:
      "The Company and the users agree to comply with all relevant applicable laws and regulations during the Term of this Agreement.",
  },
  {
    id: "6",
    MainPoint: "To restrict the use of your information",
    point: "You can request to restrict the use of your information.",
  },
  {
    id: "7",
    MainPoint: "Relationship of the parties:",
    point:
      "The Company is an independent contractor. Neither party to this Agreement will be considered employees or agents of either party. Nothing in this agreement creates a partnership, joint venture, principal and agent or an employer employee relationship. ",
  },
  {
    id: "8",
    MainPoint: "Assignment:",
    point:
      "We may transfer, assign, sub-contract or otherwise deal with our rights and/or obligations under this Agreement.  You may not transfer or assign this Agreement or your obligations thereunder. ",
  },
  {
    id: "9",
    MainPoint: "Severability:",
    point:
      " If a provision of the Terms and Conditions herein  is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect.  If any unlawful and/or unenforceable provisions would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect. ",
  },
  {
    id: "10",
    MainPoint: "Enforceability:",
    point:
      "Should any portion of this Agreement be deemed unlawful, void or unenforceable, that part will be deemed severable and will not affect the validity and enforceability of all remaining provisions which will remain in full force and effect. The failure of the Company to act on or enforce any provision of this Agreement shall not be construed as a waiver of that provision or any other provision in this Agreement.  No waiver shall be effective against the Company unless made in writing and signed by the Managing Member of the Company, and no such waiver shall be construed as a waiver in any other or subsequent instance. ",
  },
  {
    id: "11",
    MainPoint: "Notice",
    point:
      " Any notice, demand, request or report to be given or made under this Agreement shall be made in English communicated via the Portal.",
  },
  {
    id: "11",
    MainPoint: "Force Majeure :",
    point:
      " The Company is not liable under this Agreement for the non-performance caused by acts of war, terrorism, natural disasters (e.g., hurricanes, earthquakes), human-made disasters, other acts of God or nature, public health emergencies (including pandemics), public safety incidents, telecommunication or internet service interruption, strikes or other labor disputes or unrest, civil insurrections, riots, or other acts of civil disorder, acts of military, civil, or regulatory authority or any other events, conditions or causes beyond the Company’s reasonable control.",
  },
];