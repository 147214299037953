import React, { useEffect } from "react";
import { forwardRef } from "react";
import {
  FAQsData,
  HowitWorksCardInfo,
  InfoCount,
  TeamMembers,
} from "../../constants/Data";
import {
  AboutUsCard,
  FAQsCard,
  HowItWorksCard,
  InfoCard,
} from "../cards/Cards";
import { BsFillCircleFill } from "react-icons/bs";
import "react-multi-carousel/lib/styles.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "tippy.js/dist/tippy.css"; // optional
import AOS from "aos";
import "aos/dist/aos.css";
import { CarouselMobileView } from "../carousel/carousel";
// import { LazyLoadImage } from "react-lazy-load-image-component";

const HeaderSection = () => {
  return (
    <section className="text-gray-600 h-full xl:h-screen md:h-full w-full bgContainer">
      <div className="flex flex-col md:flex-row w-full h-full pt-20 ">
        <div className="w-full h-full xl:hidden block">
          <div className="w-full h-full hero-img" />
        </div>
        <div className="w-full h-full md:h-[60%] lg:h-full xl:h-full py-8 lg:py-0">
          <div className="w-full flex items-center ">
            <img
              src="/assets/icons/skoolbus-hero-bg-line.svg"
              alt=""
              className="hero-bg-line hidden lg:block "
            />
          </div>
          <div className="w-full flex flex-col xl:justify-start h-full  lg:pt-10 lg:pl-24 p-0 lg:gap-10 xl:gap-14">
            <h1 className="p-6 lg:p-0 leading-tight text-center lg:text-left text-3xl md:text-5xl lg:text-4xl xl:text-5xl 2xl:text-6xl  font-700 text-[#444444]">
              Keep your child safe,
              <br /> Wherever you are.
            </h1>
            <p className=" text-center lg:text-left w-full px-4 lg:px-0 text-base lg:text-xl xl:text-3xl md:px-24 lg:text-full xl:w-full 2xl:w-3/4 md:text-2xl ">
              Rest assured, save time and ensure safety with a real-time school
              bus tracking system.
            </p>
            <div className="p-6 lg:p-0 flex justify-center lg:justify-start gap-6 mt-4 lg:mt-0 items-center">
              <a
                href="https://apps.apple.com/lk/app/skoolbus/id6465951976"
                tabIndex="0"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-36 hover:scale-95 duration-500"
                  src="https://imgs.search.brave.com/PhFkigbrIwPnUTaDWMBqhaVOfCkbOMWS6R0MzoQ5Fwg/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9hc3Nl/dHMuc3RpY2twbmcu/Y29tL2ltYWdlcy81/YTkwMmRiOTdmOTY5/NTFjODI5MjI4NzQu/cG5n"
                  alt="bn45"
                />
              </a>
              {/* https://play.google.com/store/search?q=SkoolBus&c=apps&hl=en&gl=US */}
              <a
                href="https://play.google.com/store/search?q=SkoolBus&c=apps&hl=en&gl=US"
                target="_blank"
                rel="noreferrer"
                tabIndex="0"
              >
                <img
                  className="w-40 -mt-px hover:scale-95 duration-500"
                  src="https://www.freepnglogos.com/uploads/play-store-logo-png/play-store-logo-nisi-filters-australia-11.png"
                  alt="bn45"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="w-full h-full hidden lg:block">
          <div className="w-full h-full justify-end items-end xl:hidden block">
            <img src="/assets/images/Home.png" alt="" className="" />
          </div>
          <div className="w-full h-full flex justify-end items-end ">
            <div className="w-full  h-full hero-img lg:block md:block hidden" />
          </div>
        </div>
      </div>
    </section>
  );
};

const HeroSection = () => {
  return (
    <div className="scroll-section w-full h-screen flex flex-col lg:flex-row">
      <div className="hidden lg:block">
        <video
          autoPlay
          muted
          loop
          className="absolute top-0 left-0 w-full h-full z-0 object-cover hidden lg:block"
          //src="/assets/videos/skoolbus-hero-bg-video.mp4"
          //src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        >
          <source
            src="/assets/videos/skoolbus-hero-bg-video.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="w-full lg:w-[45%] h-full z-10 sm:hidden ">
        <img
          src="/assets/images/skoolbus-app-preview.png"
          alt=""
          className="pt-2"
        />
      </div>
      <div className="lg:w-[55%] h-full z-10">
        <div className="w-full lg:h-[30vh] flex items-center">
          <img
            src="/assets/icons/skoolbus-hero-bg-line.svg"
            alt=""
            className="hero-bg-line hidden lg:block "
          />
        </div>
        <div className="w-full lg:h-[70vh] flex flex-col lg:justify-start h-full justify-center lg:pl-24 p-0  lg:gap-14">
          <h1 className="text-3xl md:text-[32px] p-6 lg:p-0 leading-tight text-center lg:text-left lg:text-7xl font-700">
            Keep your child safe, Wherever you are.
          </h1>
          <p className="text-base text-center lg:text-left w-full px-4 lg:px-0 lg:text-3xl md:px-24 lg:w-3/4">
            Rest assured, save time and ensure safety with a real-time school
            bus tracking system.
          </p>
          <div className="p-6 lg:p-0 flex justify-center lg:justify-start gap-6 mt-4 lg:mt-0">
            <a
              href="https://apps.apple.com/lk/app/skoolbus/id6465951976"
              tabIndex="0"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="bn46 button-img-ios"
                src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                alt="bn45"
              />
            </a>
            {/* https://play.google.com/store/search?q=SkoolBus&c=apps&hl=en&gl=US */}
            <a
              href="https://play.google.com/store/search?q=SkoolBus&c=apps&hl=en&gl=US"
              target="_blank"
              rel="noreferrer"
              tabIndex="0"
            >
              <img
                className="bn45 button-img-android"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png"
                alt="bn45"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-[45%] h-full hero-img z-10 hidden sm:block " />
    </div>
  );
};

const HeroSectionResponsive = () => {
  return (
    <div className="scroll-section w-full h-screen flex flex-col lg:flex-row">
      {/* <div className="hidden lg:block">
        <video
          autoPlay
          muted
          loop
          className="absolute top-0 left-0 w-full h-full z-0 object-cover hidden lg:block"
          //src="/assets/videos/skoolbus-hero-bg-video.mp4"
          //src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        >
          <source
            src="/assets/videos/skoolbus-hero-bg-video.mp4"
            type="video/mp4"
          />
        </video>
      </div> */}
      <div className="w-full lg:w-[45%] h-full z-10 sm:hidden ">
        <img
          src="/assets/images/skoolbus-app-preview.png"
          alt=""
          className="pt-2"
        />
      </div>
      <div className="lg:w-[55%] h-full z-10">
        <div className="w-full lg:h-[30vh] flex items-center">
          <img
            src="/assets/icons/skoolbus-hero-bg-line.svg"
            alt=""
            className="hero-bg-line hidden lg:block "
          />
        </div>
        <div className="w-full lg:h-[70vh] flex flex-col lg:justify-start h-full justify-center lg:pl-24 p-0  lg:gap-14">
          <h1 className="text-3xl md:text-[32px] p-6 lg:p-0 leading-tight text-center lg:text-left lg:text-7xl font-700">
            Keep your child safe, Wherever you are.
          </h1>
          <p className="text-base text-center lg:text-left w-full px-4 lg:px-0 lg:text-3xl md:px-24 lg:w-3/4">
            Rest assured, save time and ensure safety with a real-time school
            bus tracking system.
          </p>
          <div className="p-6 lg:p-0 flex justify-center lg:justify-start gap-6 mt-4 lg:mt-0">
            <a
              href="https://apps.apple.com/lk/app/skoolbus/id6465951976"
              tabIndex="0"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="bn46 button-img-ios"
                src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                alt="bn45"
              />
            </a>

            <a
              href="https://play.google.com/store/search?q=SkoolBus&c=apps&hl=en&gl=US"
              target="_blank"
              rel="noreferrer"
              tabIndex="0"
            >
              <img
                className="bn45 button-img-android"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png"
                alt="bn45"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-[45%] h-full hero-img z-10 hidden sm:block " />
    </div>
  );
};

const AboutHeroSection = () => {
  return (
    <div className="w-full h-screen flex flex-col lg:flex-row">
      <div className="hidden lg:block">
        <video
          autoPlay
          muted
          loop
          className="absolute top-0 left-0 w-full h-full z-0 object-cover hidden lg:block"
          //src="/assets/videos/skoolbus-hero-bg-video.mp4"
          //src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        >
          <source
            src="/assets/videos/skoolbus-hero-bg-video.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="lg:w-[55%] h-full z-10">
        <div className="w-full lg:h-[30vh] flex items-center">
          <img
            src="/assets/icons/skoolbus-hero-bg-line.svg"
            alt=""
            className="hero-bg-line"
          />
        </div>
        <div className="w-full lg:h-[70vh] flex flex-col lg:justify-start lg:pl-24 gap-4 lg:gap-10">
          <h1 className="text-[32px] p-6 lg:p-0 leading-tight text-center lg:text-left lg:text-7xl font-700 hero-title">
            About Us
          </h1>
          <p className="text-lg text-center lg:text-left w-full px-6 lg:px-0 lg:text-2xl md:px-24 lg:w-[90%] ">
            Hop on the SkoolBus and ensure the safety of your child. Follow the
            journey from start to finish, track the bus, find the current route,
            get alerts, fetch driver details, and contact the driver directly.
            Monitor your child's journey without leaving your home or office and
            rest assured of your child's safety.
          </p>
          <p className="text-lg text-center lg:text-left w-full px-6 lg:px-0 lg:text-2xl md:px-24 lg:w-3/4 ">
            Monitor your child's journey without leaving your home or office and
            rest assured of your child's safety.
          </p>
        </div>
      </div>
      <div className="w-full lg:w-[45%] h-full about-img z-10 hidden sm:block"></div>
    </div>
  );
};

const AboutHeroResponsive = () => {
  return (
    <div className="w-full h-screen flex flex-col lg:flex-row">
      {/* <div className="hidden lg:block">
        <video
          autoPlay
          muted
          loop
          className="absolute top-0 left-0 w-full h-full z-0 object-cover hidden lg:block"
          //src="/assets/videos/skoolbus-hero-bg-video.mp4"
          //src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        >
          <source
            src="/assets/videos/skoolbus-hero-bg-video.mp4"
            type="video/mp4"
          />
        </video>
      </div> */}
      <div className="lg:w-[55%] h-full z-10">
        <div className="w-full lg:h-[30vh] flex items-center">
          <img
            src="/assets/icons/skoolbus-hero-bg-line.svg"
            alt=""
            className="hero-bg-line"
          />
        </div>
        <div className="w-full lg:h-[70vh] flex flex-col lg:justify-start lg:pl-24 gap-4 lg:gap-10">
          <h1 className="text-[32px] p-6 lg:p-0 leading-tight text-center lg:text-left lg:text-7xl font-700 hero-title">
            About Us
          </h1>
          <p className="text-lg text-center lg:text-left w-full px-6 lg:px-0 lg:text-2xl md:px-24 lg:w-[90%] ">
            Hop on the SkoolBus and ensure the safety of your child. Follow the
            journey from start to finish, track the bus, find the current route,
            get alerts, fetch driver details, and contact the driver directly.
            Monitor your child's journey without leaving your home or office and
            rest assured of your child's safety.
          </p>
          <p className="text-lg text-center lg:text-left w-full px-6 lg:px-0 lg:text-2xl md:px-24 lg:w-3/4 ">
            Monitor your child's journey without leaving your home or office and
            rest assured of your child's safety.
          </p>
        </div>
      </div>
      <div className="w-full lg:w-[45%] h-full about-img z-10 hidden sm:block"></div>
    </div>
  );
};

const HeroTestimonialSection = () => {
  return (
    <div className="flex flex-col lg:py-20 justify-center items-center text-center p-4 lg:p-10 gap-12 py-10 w-full h-full">
      <div className="text-2xl lg:text-5xl font-700 w-full lg:px-20 md:text-[48px]">
        Why parents love <span className="text-main">Skoolbus</span>
      </div>
      <div className=" flex flex-col text-black opacity-80 xl:w-[40%] ">
        <span className="lg:text-2xl xl:text-xl text-base  mx-auto md:text-3xl md:px-10 ">
          Skoolbus is a reassuring, reliable and indispensable tool for any busy
          parent to ensure their child's safety.
        </span>
      </div>
      {/* <div className="lg:block hidden">
        <div className="grid grid-rows-1 grid-cols-5 lg:gap-16 px-10 lg:px-0 gap-4 md:p-10 w-full">
          {Testimonial.map((data, i) => {
            return (
              <div key={i}>
                <TestimonialCard data={data} key={i} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="block lg:hidden w-full h-full">
        <div className="flex flex-row justify-center items-center w-full h-full">
          <CarouselMobileView />
        </div>
      </div> */}
    </div>
  );
};

const RightsideSection = ({
  image,
  title,
  subtitle,
  para,
  responsiveImage,
}) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    AOS.refresh(); // Refresh AOS on component re-render
  });

  return (
    <div className="flex flex-col gap-0 lg:gap-0 md:gap-10 lg:flex-row xl:h-screen lg:h-full z-50 w-full">
      <div
        className="w-full h-full md:h-1/2 z-[-10]"
        // data-aos="zoom-in" // Example animation: fade-right
      >
        <img
          src={image}
          alt={title}
          className="w-full xl:h-screen md:h-[800px] lg:h-full object-cover "
        />
      </div>
      <div
        className="flex flex-col justify-center items gap-5 text-textMain w-full p-8 md:p-20 lg:p-0 xl:p-0 text-center lg:text-left -mt-48 sm:-mt-0 "
        data-aos="zoom-in" // Example animation: fade-left
      >
        <div className="block sm:hidden h-[380px] mx-auto ">
          <img src={responsiveImage} alt="" className="object-contain h-full" />
        </div>
        <div className="xl:pl-48 lg:pl-10 flex flex-col gap-8 lg:-mt-0 mb-4 lg:mb-0">
          <div
            className="xl:w-2/3 text-2xl font-700 xl:pr-10 md:text-4xl lg:text-5xl"
            data-aos="zoom-in" // Example animation: zoom-in
          >
            {title}
          </div>
          <div
            className="text-[#777777]  font-medium text-lg md:text-2xl lg:text-2xl  lg:w-2/3"
            data-aos="fade-up" // Example animation: fade-up
          >
            {subtitle}
          </div>
          <div
            className="text-cBlack font-500 text-base md:text-xl  lg:w-3/4"
            data-aos="fade-up" // Example animation: fade-up
          >
            {para}
          </div>
          <div
            className="flex mx-auto NeueMedium tracking-wide lg:mx-0 mt-6 "
            data-aos="fade-down" // Example animation: fade-up
          >
            {/* <Link to={`#${id}`}>
              <ButtonSecondary text={buttonText} />
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const LeftsideSection = ({ image, title, subtitle, para, responsiveImage }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    AOS.refresh(); // Refresh AOS on component re-render
  });
  return (
    <div className="flex flex-col gap-0 lg:gap-0 md:gap-10 lg:flex-row xl:h-screen lg:h-full z-50">
      <div className="lg:hidden block w-full h-full z-[-10]">
        <img
          src={image}
          alt={title}
          className="w-full lg:h-screen md:h-[800px] object-cover "
        />
      </div>
      {/* <div className="block sm:hidden absolute w-full">
        <div className="relative top-80 aspect-square h-[380px] flex justify-center m-auto w-full">
          <img src={responsiveImage} alt="" className="object-cover h-full " />
        </div>
      </div> */}
      <div className="flex flex-col justify-center items gap-5 text-textMain w-full p-8 md:p-20 lg:p-0 text-center lg:text-left -mt-48 sm:-mt-0 ">
        <div className="xl:pl-32 lg:pl-10 flex flex-col gap-8 -mt-16 lg:-mt-0 mb-4 lg:mb-0">
          <div
            className="block sm:hidden h-[380px] mx-auto "
            data-aos="zoom-in"
          >
            <img
              src={responsiveImage}
              alt=""
              className="object-contain h-full"
            />
          </div>
          <div
            className="xl:w-2/3  text-2xl font-700 lg:pr-10 md:text-4xl lg:text-5xl"
            data-aos="zoom-in" // Example animation: zoom-in
          >
            {title}
          </div>
          <div
            className="text-[#777777]  font-medium text-lg md:text-2xl lg:text-2xl  lg:w-2/3"
            data-aos="fade-up" // Example animation: fade-up
          >
            {subtitle}
          </div>
          <div
            className="text-cBlack font-500 text-base md:text-xl  lg:w-3/4"
            data-aos="fade-up" // Example animation: fade-up
          >
            {para}
          </div>
          <div
            className="flex mx-auto NeueMedium tracking-wide lg:mx-0 mt-6 "
            data-aos="fade-down" // Example animation: fade-up
          >
            {/* <Link to={`#${id}`}>
              <ButtonSecondary text={buttonText} />
            </Link> */}
          </div>
        </div>
      </div>
      <div className="lg:block hidden w-full h-full z-[-10]">
        <img
          src={image}
          alt={title}
          className="w-full xl:h-screen object-cover"
        />
      </div>
    </div>
  );
};

const HeroSecondarySection = ({ title, para, image }) => {
  return (
    <div className="w-full pt-56 lg:pt-40 lg:h-screen">
      <div className="flex flex-row h-full items-center">
        <div className="text-textMain w-full h-full flex flex-col justify-center items-center">
          <div className="flex flex-col lg:flex-row gap-10 h-full px-8 justify-center items-center">
            <div className="flex flex-col gap-10 h-full justify-center items-center lg:items-start">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="sm:text-6xl text-5xl font-semibold flex NeueBold text-center "
              >
                {title}
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="text-base sm:text-lg font-medium text-opacity-80 text-center lg:text-start max-w-[540px] w-full Neue"
              >
                {para}
              </motion.div>
            </div>
            <div className="h-full flex flex-col justify-end items-end">
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
                className="max-w-[610px]"
              >
                <motion.img
                  initial={{ rotateY: 45, rotateX: -45, scale: 0 }}
                  animate={{ rotateY: 0, rotateX: 0, scale: 1 }}
                  transition={{ duration: 1 }}
                  src={image}
                  alt={title}
                  className="object-cover lg:h-full"
                />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AboutVideoSection = () => {
  return (
    <div className="h-full w-full flex flex-col p-8 sm:p-20 text-center gap-10  bg-background">
      <div className="NeueBold text-2xl sm:text-5xl font-semibold">
        Skoolbus Story
      </div>
      <div className="Neue text-base sm:text-lg font-medium max-w-[1204px] flex mx-auto">
        The story of Skoolbus began with the realization that the daily ritual
        of waiting for the school bus could be transformed into a stress-free,
        informed, and enjoyable experience. The driving force behind this
        innovation was the desire to bridge the gap between parents and school
        bus drivers, ensuring the safety and well-being of the precious cargo
        they carried – the children of Sri Lanka.
      </div>
      <div
        className="flex justify-center max-h-[500px] outline-none"
        style={{ outline: "none" }}
      >
        {/* <video
          autoPlay
          muted
          loop
          className="outline-none w-full"
          style={{ outline: "none" }}
        >
          <source
            src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
            type="video/mp4"
            className="outline-none"
            style={{ outline: "none" }}
          />
        </video> */}
        {/* <ReactPlayer
          url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
          playing={true} // Autoplay if desired
          controls={false} // Show video controls
          loop={true} // Loop the video
          muted={true} // Mute the video
          style={{ outline: "none" }}
        /> */}
        {/* <img src="assets/images/VideoImage.png" alt="" /> */}
      </div>
    </div>
  );
};

const MissionVission = () => {
  const textVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const textTransition = {
    duration: 2,
    ease: "easeInOut",
  };

  const textTypewriterVariants = {
    hidden: { width: 0 },
    visible: {
      width: "100%",
      transition: { delay: 0.6, duration: 5, ease: "easeInOut" },
    },
  };

  return (
    <motion.div
      className="text-white flex flex-col gap-20 bg-main py-20 lg:px-40 px-8"
      initial="hidden"
      animate="visible"
      variants={textVariants}
      transition={textTransition}
    >
      <motion.div
        className="flex flex-col sm:flex-row gap-10 justify-start sm:justify-start items-start sm:items-center w-full"
        variants={textTypewriterVariants}
      >
        <motion.div className="text-2xl sm:text-4xl md:w-1/3">
          <div className="w-full font-bold">Our Vision</div>
        </motion.div>
        <motion.div className="text-lg font-medium md:w-2/3">
          <div className="text-left">
            A world where children's transportation is safe, convenient, and
            worry-free.
          </div>
        </motion.div>
      </motion.div>
      <motion.div
        className="flex flex-col sm:flex-row gap-10 justify-start sm:justify-start items-start sm:items-center w-full"
        variants={textTypewriterVariants}
      >
        <motion.div className="text-2xl sm:text-4xl md:w-1/3">
          <div className="font-bold">Our Mission</div>
        </motion.div>
        <motion.div className="text-lg font-medium md:w-2/3">
          <div className="text-left">
            We're revolutionizing school transportation with our cutting-edge
            mobile app, providing unbeatable safety and convenience for children
            while empowering parents with real-time bus tracking and instant
            alerts.
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

const OurTeam = forwardRef((props, ref) => {
  return (
    <div
      className="h-full w-full flex flex-col gap-8  justify-center items-center py-10 sm:py-20 px-4 overflow-hidden"
      ref={ref}
    >
      <div className="NeueBold text-2xl sm:text-5xl font-semibold">
        Our Team
      </div>
      <div className="Neue text-base lg:text-xl xl:text-2xl font-medium text-center w-full max-w-[850px]">
        Our dedicated team is committed to ensuring safe and efficient school
        transportation, enhancing the daily commute experience for students and
        parents alike.
      </div>
      <div className="hidden sm:block">
        <div className="flex flex-row justify-center items-center px-10 xl:px-40">
          <div className="flex flex-wrap mx-auto items-center justify-center md:p-10  gap-20 ">
            {TeamMembers.map((data, i) => {
              return <AboutUsCard data={data} key={i} />;
            })}
          </div>
        </div>
      </div>
      <div className="sm:hidden block overflow-hidden">
        <CarouselMobileView />
      </div>
    </div>
  );
});

const InfoSection = () => {
  return (
    <div className="z-0 w-full lg:h-screen 2xl:h-full bg-white flex flex-col gap-12 p-4 lg:p-20 lg:px-40 justify-center items-center">
      <div className="NeueBold text-2xl sm:text-5xl font-semibold text-center lg:text-start">
        Join Along With Us!
      </div>
      <div className="Neue text-base lg:text-lg font-medium text-center w-full max-w-[750px]">
        Join us on this journey of innovation, security, and community. Skoolbus
        is not just about tracking it's about creating a connected and
        worry-free school bus experience for everyone involved.
      </div>
      <div className="flex flex-row justify-center items-center">
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 md:gap-10 lg:gap-x-40 gap-y-10 justify-center items-center">
          {InfoCount.map((data, i) => {
            return <InfoCard data={data} key={i} />;
          })}
        </div>
      </div>
    </div>
  );
};

const HowItWorksCardSection = () => {
  return (
    <div className="w-full h-full flex flex-col gap-4 sm:p-20 sm:px-40 justify-center items-center p-10 z-10">
      <div
        data-aos="fade-up"
        data-aos-duration="3000"
        className="NeueBold text-2xl sm:text-5xl font-semibold"
      >
        How Does it Work ?
      </div>
      <div
        className="Neue text-base lg:text-xl font-medium text-center w-full max-w-[550px]"
        data-aos="fade-up"
        data-aos-duration="3000"
      >
        Can Skoolbus keep my child safe while travelling to and from school?
      </div>
      <div className="grid sm:grid-cols-1 xl:grid-cols-2 w-full gap-x-20 ] gap-y-12 pt-12">
        {HowitWorksCardInfo.map((data, i) => {
          return <HowItWorksCard data={data} key={i} />;
        })}
      </div>
    </div>
  );
};

const ChildSafetySection = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div className="w-full flex flex-col gap-5 sm:p-20 sm:px-40 p-10 justify-center items-center">
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.8 }}
        className="NeueBold text-2xl sm:text-5xl font-semibold text-center lg:text-start"
      >
        How safe is your child with Skoolbus?
      </motion.div>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.8 }}
        className="Neue text-base lg:text-lg font-medium text-center w-full max-w-[750px]"
      >
        Your child’s safety is paramount and will depend on our security
        measures and your own actions. Consider the following to keep your child
        safe:
      </motion.div>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.8 }}
      >
        <ul>
          <li className="flex flex-row gap-2 items-center">
            <BsFillCircleFill className="text-xs" />
            <div className="Neue">scelerisque nullam sagittis, pulvinar.</div>
          </li>
          <li className="flex flex-row gap-2 items-center">
            <BsFillCircleFill className="text-xs" />
            <div className="Neue">scelerisque nullam sagittis, pulvinar.</div>
          </li>
          <li className="flex flex-row gap-2 items-center">
            <BsFillCircleFill className="text-xs" />
            <div className="Neue">scelerisque nullam sagittis, pulvinar.</div>
          </li>
          <li className="flex flex-row gap-2 items-center">
            <BsFillCircleFill className="text-xs" />
            <div className="Neue">scelerisque nullam sagittis, pulvinar.</div>
          </li>
        </ul>
      </motion.div>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.8 }}
        className="lg:px-40 lg:pb-10"
      >
        <motion.img
          initial={{ rotateY: 45, rotateX: -45, scale: 0 }}
          animate={{ rotateY: 0, rotateX: 0, scale: 1 }}
          transition={{ duration: 1 }}
          src="/assets/images/ChildSafety.png"
          alt=""
        />
      </motion.div>
    </div>
  );
};

const HowitWorksRightSide = ({ image, title, para }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="z-20">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { opacity: 0, rotateY: 45, rotateX: -45 },
            visible: { opacity: 1, rotateY: 0, rotateX: 0 },
          }}
          transition={{ duration: 1 }}
          className="z-20"
        >
          <img src={image} alt={title} className="z-20" />
        </motion.div>
      </div>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.8 }}
        className=""
      >
        <div className="flex flex-col gap-7">
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="font-bold text-7xl"
          >
            {title}
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="font-normal text-3xl max-w-[450px] w-full"
          >
            {para}
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

const HowitWorksLeftSide = ({ image, title, para }) => {
  return (
    <div className="relative flex flex-col lg:flex-row p-10 lg:pt-20 lg:pb-0">
      <div className="block lg:hidden w-full h-full">
        <div
          className="w-full h-full flex justify-center items-end "
          data-aos="zoom-in"
          data-aos-duration="3000"
        >
          <div className="">
            <img src={image} alt={title} className="w-full object-cover" />
          </div>
        </div>
      </div>
      <div
        className="flex flex-col justify-center gap-5 text-textMain w-full "
        data-aos="zoom-in"
        data-aos-duration="3000"
      >
        <div className="lg:pt-0 lg:pl-48 flex flex-col gap-10 text-center lg:text-start pt-10">
          <div
            data-aos="zoom-in"
            data-aos-duration="3000"
            className="lg:max-w-[490px] text-xl sm:text-4xl NeueBold lg:pr-10"
          >
            {title}
          </div>
          <div
            className="text-textMain Neue font-medium text-base sm:text-lg lg:max-w-[460px]"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            {para}
          </div>
        </div>
      </div>
      <div
        className="lg:block hidden w-full h-full "
        data-aos="zoom-in"
        data-aos-duration="3000"
      >
        <div className="w-full h-full flex justify-center items-end ">
          <div>
            <img src={image} alt={title} className="w-full object-cover" />
          </div>
        </div>
      </div>
    </div>
  );
};

const FAQSection = () => {
  return (
    <div className="w-full h-full py-10 px-4 xl:px-52 lg:px-30 md:gap-10 xl:py-20 flex flex-col  lg:gap-0 gap-10">
      <div className=" ">
        <div className="font-semibold text-lg text-center my-5 pb-10">
          Uncover answers to your diverse inquiries about Skoolbus within our
          specialized FAQ section.
          <br /> Your journey to a seamless and secure school bus experience
          begins here!
        </div>
        <div className=" flex flex-col gap-8 ">
          <div className="flex flex-col xl:px-28 lg:pb-20 gap-4 lg:gap-0">
            {FAQsData.map((items, i) => (
              <FAQsCard items={items} key={i} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const DownloadSection = () => {
  return (
    <div className="w-full flex flex-col justify-between gap-0 z-[999] md:-mt-20 lg:-mt-0 ">
      <div className="flex flex-col gap-6 justify-center items-center lg:pt-32 md:pt-10  md:px-20 px-4 lg:px-0">
        <div className="sm:text-5xl text-2xl">Download the App</div>
        <div className="text-textMain Neue font-medium text-base md:text-xl sm:text-lg  xl:px-80 lg:px-40 text-center">
          Say goodbye to those long anxious waits during your child's daily
          commute. Make Skoolbus a valuable addition to prioritize your child's
          safety and enjoy a worry-free life. Happy Skoolbus tracking!
        </div>
        <div className="">
          <div className="p-6 lg:p-10 flex justify-center lg:justify-start gap-6 mt-4 lg:mt-0 items-center">
            <a
              href="https://apps.apple.com/lk/app/skoolbus/id6465951976"
              tabIndex="0"
            >
              <img
                className="w-36 hover:scale-95 duration-500"
                src="https://imgs.search.brave.com/PhFkigbrIwPnUTaDWMBqhaVOfCkbOMWS6R0MzoQ5Fwg/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9hc3Nl/dHMuc3RpY2twbmcu/Y29tL2ltYWdlcy81/YTkwMmRiOTdmOTY5/NTFjODI5MjI4NzQu/cG5n"
                alt="bn45"
              />
            </a>
            <a
              href="https://play.google.com/store/search?q=SkoolBus&c=apps&hl=en&gl=US"
              tabIndex="0"
            >
              <img
                className="w-40 hover:scale-95 duration-500 -mt-px"
                src="https://www.freepnglogos.com/uploads/play-store-logo-png/play-store-logo-nisi-filters-australia-11.png"
                alt="bn45"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center px-8 sm:px-0  lg:w-2/5 m-auto">
        <img
          src="/assets/images/downloadSectionWeb.png"
          alt="download"
          className=""
        />
      </div>
      <div className="absolute left-0 sm:mt-60 scale-50 sm:scale-100 -mt-20 -ml-32 lg:-ml-0 lg:mt-60 -z-10">
        <img
          src="/assets/images/Asset3.svg"
          alt="download"
          className="xl:block hidden"
        />
      </div>
    </div>
  );
};

const GetstartedLeftSide = ({ Heading, para, sectionImage }) => {
  return (
    <div className="flex flex-col xl:flex-row w-full justify-center items-center xl:px-40 p-8 xl:gap-40 gap-8">
      <div className="lg:w-1/2 w-full">
        <img src={sectionImage} alt="" className="w-full" />
      </div>
      <div className="xl:w-1/2 flex flex-col gap-8 ">
        <div className="text-2xl sm:text-5xl font-bold xl:w-2/3 lg:w-full w-full xl:text-left text-center">
          {Heading}
        </div>
        <div className="text-base sm:text-2xl font-normal xl:w-4/5 lg:w-full w-full xl:text-left text-center">
          {para}
        </div>
      </div>
    </div>
  );
};

const GetstartedRightSide = ({ Heading, para, sectionImage }) => {
  return (
    <div className="flex flex-col xl:flex-row w-full justify-center items-center xl:px-40 px-8 xl:gap-40 gap-8">
      <div className="lg:w-1/2 w-full mx-auto  xl:hidden  block">
        <img src={sectionImage} alt="" className="w-full" />
      </div>
      <div className="xl:w-1/2 w-full flex flex-col gap-8">
        <div className="text-2xl sm:text-5xl font-bold  w-full xl:text-left text-center">
          {Heading}
        </div>
        <div className="text-base sm:text-2xl font-normal  w-full xl:text-left text-center">
          {para}
        </div>
      </div>
      <div className="w-1/2 mx-auto hidden xl:block">
        <img src={sectionImage} alt="" className="w-full" />
      </div>
    </div>
  );
};

export {
  HeroSection,
  HeroTestimonialSection,
  RightsideSection,
  LeftsideSection,
  HeroSecondarySection,
  AboutVideoSection,
  MissionVission,
  OurTeam,
  InfoSection,
  HowItWorksCardSection,
  ChildSafetySection,
  HowitWorksRightSide,
  HowitWorksLeftSide,
  FAQSection,
  DownloadSection,
  GetstartedLeftSide,
  GetstartedRightSide,
  HeroSectionResponsive,
  AboutHeroSection,
  AboutHeroResponsive,
  HeaderSection,
};
