import React from "react";
import {
  GetstartedLeftSide,
  GetstartedRightSide,
  HowItWorksCardSection,
} from "../components/sections/Sections";
import AOS from "aos";
import "aos/dist/aos.css";
import { GoDotFill } from "react-icons/go";

AOS.init();
const HowitWorks = () => {
  return (
    <div className="z-10 w-full h-full">
      <section className="text-gray-600 h-full xl:h-screen lg:h-full w-full  bgContainer">
        <div className="flex flex-col lg:flex-row w-full h-full pt-20 ">
          <div className="w-full h-full lg:hidden block">
            <div className="w-full h-full flex justify-end items-end">
              <img src="/assets/images/getstarted.svg" alt="" className="" />
            </div>
          </div>
          <div className="w-full h-full md:h-[60%] lg:h-full xl:h-full py-8 lg:py-0">
            <div className="w-full flex items-center ">
              <img
                src="/assets/icons/skoolbus-hero-bg-line.svg"
                alt=""
                className="hero-bg-line hidden xl:block "
              />
            </div>
            <div className="w-full flex flex-col xl:justify-start h-full  lg:pt-10 lg:pl-24 p-0 lg:gap-10 xl:gap-14">
              <h1 className="p-6 lg:p-0 leading-tight text-center lg:text-left text-3xl md:text-5xl lg:text-5xl xl:text-6xl font-700 text-[#444444]">
                Let’s get Started
              </h1>
              <p className=" text-center lg:text-left w-full px-4 lg:px-0 text-base lg:text-2xl xl:text-3xl md:px-24 xl:w-full md:text-2xl ">
                Where the journey to education begins!
                <br /> We're dedicated to ensuring safe and reliable
                transportation for students. Let's embark on this exciting
                adventure together. Let's get started!
              </p>
              <div className="p-6 lg:p-0 flex justify-center lg:justify-start gap-6 mt-4 lg:mt-0 items-center">
                <a
                  href="https://apps.apple.com/lk/app/skoolbus/id6465951976"
                  tabIndex="0"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="w-36 hover:scale-95 duration-500"
                    src="https://imgs.search.brave.com/PhFkigbrIwPnUTaDWMBqhaVOfCkbOMWS6R0MzoQ5Fwg/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9hc3Nl/dHMuc3RpY2twbmcu/Y29tL2ltYWdlcy81/YTkwMmRiOTdmOTY5/NTFjODI5MjI4NzQu/cG5n"
                    alt="bn45"
                  />
                </a>
                {/* https://play.google.com/store/search?q=SkoolBus&c=apps&hl=en&gl=US */}
                <a
                  href="https://play.google.com/store/search?q=SkoolBus&c=apps&hl=en&gl=US"
                  target="_blank"
                  rel="noreferrer"
                  tabIndex="0"
                >
                  <img
                    className="w-40 -mt-px hover:scale-95 duration-500"
                    src="https://www.freepnglogos.com/uploads/play-store-logo-png/play-store-logo-nisi-filters-australia-11.png"
                    alt="bn45"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="w-full h-full hidden lg:block">
            <div className="w-full h-full justify-end items-end xl:hidden block">
              <img src="/assets/images/getstarted.svg" alt="" className="" />
            </div>
            <div className="w-full h-full flex justify-end items-end ">
              <div className="w-full  h-full getstarted-img xl:block hidden" />
            </div>
          </div>
        </div>
      </section>
      <HowItWorksCardSection />
      <div className="flex xl:flex-row flex-col w-full items-center lg:px-40 px-6 gap-8 sm:gap-0">
        <div className="w-full lg:hidden block">
          <img
            src="/assets/images/ChildSafetyNew.png"
            alt=""
            className="w-full py-8"
          />
        </div>
        <div className="flex flex-col gap-8 py-4">
          <div className="text-2xl sm:text-5xl font-bold w-full text-center xl:text-left">
            Your Child’s Safety with Skoolbus
          </div>
          <div className="text-lg sm:text-2xl font-normal xl:w-4/5 text-center xl:text-left w-full">
            At Skoolbus, the safety of your child is our top priority. Our
            school bus tracking mobile app is not just a convenience but a
            powerful tool dedicated to ensuring the well-being of every precious
            passenger.
          </div>
          <div className="flex flex-col gap-4 py-4 mx-auto xl:mx-0">
            <div className="flex gap-2 items-center sm:text-xl text-base font-normal">
              <GoDotFill className="text-lg" />
              Emergency Alerts
            </div>
            <div className="flex gap-2 items-center sm:text-xl text-base font-normal">
              <GoDotFill className="text-lg" />
              Secure Check-in/Check-out
            </div>
            <div className="flex gap-2 items-center sm:text-xl text-base font-normal">
              <GoDotFill className="text-lg" />
              Real-time GPS Tracking
            </div>
            {/* <div className="flex gap-2 items-center sm:text-xl text-base font-normal">
              <GoDotFill />
              scelerisque nullam sagittis sagittis, pulvinar
            </div> */}
          </div>
        </div>
        <div className="w-full hidden lg:block ">
          <img
            src="/assets/images/ChildSafetyNew.png"
            alt=""
            className="w-full object-contain"
          />
        </div>
      </div>
      <div className="text-center lg:px-40 pt-10 xl:py-40 pb-5 flex flex-col gap-10 px-8">
        <div className="sm:text-5xl text-2xl font-bold">How it works? </div>
        <div className="text-base sm:text-xl font-medium">
          Revolutionizing the school bus experience for parents, drivers, and
          students alike. Discover how our innovative mobile app works
          seamlessly to ensure safety, communication, and peace of mind
          throughout every school bus journey.
        </div>
      </div>
      <div className="">
        <div className="flex justify-between w-full -z-50 absolute">
          <div className="w-full absolute lg:-my-[420px]">
            <img
              src="/assets/images/getstartedleftNew.png"
              alt=""
              className="hidden xl:block"
            />
            <img
              src="/assets/images/MobileHowItStartedLeft.png"
              alt=""
              className="sm:hidden block"
            />
          </div>
          <div className="w-full flex justify-end absolute mb-80">
            <img
              src="/assets/images/getstartedrightNew.png"
              alt=""
              className="hidden xl:block"
            />
            <img
              src="/assets/images/MobileHowItStartedRight.png"
              alt=""
              className="sm:hidden block"
            />
          </div>
        </div>
        <GetstartedLeftSide
          Heading={"Use GPS Technology"}
          para={
            "Once the Skoolbus app is setup you can track your child's bus in real-time using GPS technology. Now you know the exact location of the bus and its estimated time of arrival at the bus stop."
          }
          sectionImage={"/assets/images/UseGPS.png"}
        />
        <div className="sm:py-10" />
        <div className="flex justify-between w-full -z-50 absolute">
          <div className="w-full absolute lg:-my-[420px]">
            <img
              src="/assets/images/MobileHowItStartedLeft.png"
              alt=""
              className="sm:hidden block"
            />
          </div>
          <div className="w-full flex justify-end absolute mb-80">
            <img
              src="/assets/images/MobileHowItStartedRight.png"
              alt=""
              className="sm:hidden block"
            />
          </div>
        </div>
        <GetstartedRightSide
          Heading={"Track Progress"}
          para={
            "Imagine watching in real-time when the bus is approaching the designated bus stop, when your child boards or disembarks from the bus, or arrives at school or back home? "
          }
          sectionImage={"/assets/images/TrackProgress.png"}
        />

        <div className="flex justify-between w-full -z-50 absolute">
          <div className="w-full absolute lg:-my-[420px]">
            <img
              src="/assets/images/getstartedleftNew.png"
              alt=""
              className="hidden xl:block"
            />
            <img
              src="/assets/images/MobileHowItStartedLeft.png"
              alt=""
              className="sm:hidden block"
            />
          </div>
          <div className="w-full flex justify-end absolute mb-80">
            <img
              src="/assets/images/getstartedrightNew.png"
              alt=""
              className="hidden xl:block"
            />
            <img
              src="/assets/images/MobileHowItStartedRight.png"
              alt=""
              className="sm:hidden block"
            />
          </div>
        </div>

        <div className="flex flex-col xl:flex-row w-full justify-center items-center xl:px-40 lg:px-10 p-8 xl:gap-40 gap-8">
          <div className="lg:w-1/2 w-full">
            <img
              src="/assets/images/contactDriverNew.png"
              alt=""
              className="w-full"
            />
          </div>
          <div className="xl:w-1/2 flex flex-col gap-8 ">
            <div className="text-2xl sm:text-5xl font-bold w-full xl:text-left text-center">
              Contact Driver
            </div>
            <div className="text-base sm:text-2xl font-normal xl:w-4/5 w-full xl:text-left text-center ">
              What if there's an unforeseen circumstance like a delay, an
              accident, or if your child doesn't board the bus as expected?
              Simply contact the driver directly.
            </div>
          </div>
        </div>
        <div className="sm:py-16" />
        <div className="flex justify-between w-full -z-50 absolute">
          <div className="w-full absolute lg:-my-[420px]">
            <img
              src="/assets/images/MobileHowItStartedLeft.png"
              alt=""
              className="sm:hidden block"
            />
          </div>
          <div className="w-full flex justify-end absolute mb-80">
            <img
              src="/assets/images/MobileHowItStartedRight.png"
              alt=""
              className="sm:hidden block"
            />
          </div>
        </div>
        <GetstartedRightSide
          Heading={"Alerts and Notifications"}
          para={
            "That's not all, set up alerts and notifications so you are informed as and when your child boards the bus and gets off the bus whether to reach school or return home."
          }
          sectionImage={"/assets/images/Notifications.png"}
        />
        <div className="flex justify-between w-full -z-50 absolute">
          <div className="w-full absolute lg:-my-[420px]">
            <img
              src="/assets/images/getstartedleftNew.png"
              alt=""
              className="hidden xl:block"
            />
            <img
              src="/assets/images/MobileHowItStartedLeft.png"
              alt=""
              className="sm:hidden block"
            />
          </div>
          <div className="w-full flex justify-end absolute mb-80">
            <img
              src="/assets/images/getstartedrightNew.png"
              alt=""
              className="hidden xl:block"
            />
            <img
              src="/assets/images/MobileHowItStartedRight.png"
              alt=""
              className="sm:hidden block"
            />
          </div>
        </div>
        <GetstartedLeftSide
          Heading={"Attendance Tracking"}
          para={
            "Get instant information when your child embarks and disembarks from the bus keeping you aware of their whereabouts during the journey."
          }
          sectionImage={"/assets/images/Attendance.png"}
        />
        <div className="sm:py-10" />
        <div className="flex justify-between w-full -z-50 absolute">
          <div className="w-full absolute lg:-my-[420px]">
            <img
              src="/assets/images/MobileHowItStartedLeft.png"
              alt=""
              className="sm:hidden block"
            />
          </div>
          <div className="w-full flex justify-end absolute mb-80">
            <img
              src="/assets/images/MobileHowItStartedRight.png"
              alt=""
              className="sm:hidden block"
            />
          </div>
        </div>
        <GetstartedRightSide
          Heading={"Data Privacy"}
          para={
            "With a reputable and secure app like Skoolbus, your privacy is valued and the information you provide is secure."
          }
          sectionImage={"/assets/images/Privacy.png"}
        />
        <div className="pb-10" />
      </div>
    </div>
  );
};

export default HowitWorks;
