import React, { useEffect, useRef } from "react";
import {
  AboutVideoSection,
  DownloadSection,
  MissionVission,
  OurTeam,
} from "../components/sections/Sections";

const About = () => {
  const ourTeamRef = useRef(null);

  useEffect(() => {
    // Use the hash fragment to scroll to the "Our Team" section when the component mounts
    if (window.location.hash === "#our-team") {
      ourTeamRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div>
      {/* test */}
      <div className="">
        <section className="text-gray-600 h-full xl:h-screen lg:h-full w-full  bgContainer">
          <div className="flex flex-col lg:flex-row w-full h-full pt-20 ">
            <div className="w-full h-full lg:hidden block">
              <div className="w-full h-full flex justify-end items-end">
                <img src="/assets/images/About.png" alt="" className=" " />
              </div>
            </div>
            <div className="w-full h-full md:h-[60%] lg:h-full xl:h-full py-8 lg:py-0">
              <div className="w-full flex items-center ">
                <img
                  src="/assets/icons/skoolbus-hero-bg-line.svg"
                  alt=""
                  className="hero-bg-line hidden xl:block "
                />
              </div>
              <div className="w-full flex flex-col xl:justify-start h-full  lg:pt-10 lg:pl-24 p-0 lg:gap-10 xl:gap-14">
                <h1 className="p-6 lg:p-0 leading-tight text-center lg:text-left text-3xl md:text-5xl lg:text-5xl xl:text-6xl font-700 text-[#444444]">
                  About Us
                </h1>
                <p className=" text-center lg:text-left w-full px-4 lg:px-0 text-base lg:text-xl xl:text-2xl md:px-10 xl:w-full md:text-2xl ">
                  Hop on the SkoolBus and ensure the safety of your child.
                  Follow the journey from start to finish, track the bus, find
                  the current route, get alerts, fetch driver details, and
                  contact the driver directly. Monitor your child's journey
                  without leaving your home or office and rest assured of your
                  child's safety
                </p>
                {/* <p className="text-center lg:text-left w-full px-4 lg:px-0 text-base lg:text-xl xl:text-2xl md:px-24 xl:w-full md:text-2xl ">
                  Monitor your child's journey without leaving your home or
                  office and rest assured of your child's safety.
                </p> */}
                {/* <div className="p-6 lg:p-0 flex justify-center lg:justify-start gap-6 mt-4 lg:mt-0 items-center">
                  <a
                    href="https://apps.apple.com/lk/app/skoolbus/id6465951976"
                    tabIndex="0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-40 hover:scale-95 duration-500"
                      src="/assets/images/App store.png"
                      alt="bn45"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/search?q=SkoolBus&c=apps&hl=en&gl=US"
                    target="_blank"
                    rel="noreferrer"
                    tabIndex="0"
                  >
                    <img
                      className="w-40 -mt-px hover:scale-95 duration-500"
                      src="/assets/images/play store.png"
                      alt="bn45"
                    />
                  </a>
                </div> */}
              </div>
            </div>
            <div className="w-full h-full hidden lg:block">
              <div className="w-full h-full justify-end items-end xl:hidden block">
                <img src="/assets/images/About.png" alt="" className="" />
              </div>
              <div className="w-full h-full flex justify-end items-end -mb-1">
                <div className="w-full  h-full about-img xl:block hidden" />
              </div>
            </div>
          </div>
        </section>
      </div>
      <AboutVideoSection />
      <MissionVission />
      <OurTeam ref={ourTeamRef} />
      <DownloadSection />
    </div>
  );
};

export default About;
