export const Testimonial = [
  {
    id: 1,
    name: "Kasun Meedeniya",
    title: "Parent",
    image: "/assets/images/parent1.png",
    message:
      "Lorem  ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut tristique et egestas quis ipsum.",
  },
  {
    id: 2,
    name: "Chamarie U.",
    title: "Parent",
    image: "/assets/images/parent2.png",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut tristique et egestas quis ipsum.",
  },
  {
    id: 3,
    name: "Sagara Sampath",
    title: "Parent",
    image: "/assets/images/parent3.png",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut tristique et egestas quis ipsum.",
  },
  {
    id: 4,
    name: "Melody Tissera",
    title: "Parent",
    image: "/assets/images/parent4.png",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut tristique et egestas quis ipsum.",
  },
  {
    id: 5,
    name: "Derek Pedersen",
    title: "Parent",
    image: "/assets/images/parent5.png",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut tristique et egestas quis ipsum.",
  },
];

export const TestimonialMobile = [
  {
    id: 1,
    name: "Kasun Meedeniya",
    title: "Parent",
    image: "/assets/images/parent1.png",
  },
  {
    id: 2,
    name: "Chamarie Ukwatta",
    title: "Parent",
    image: "/assets/images/parent2.png",
  },
  {
    id: 3,
    name: "Sagara Sampath",
    title: "Parent",
    image: "/assets/images/parent3.png",
  },
  {
    id: 4,
    name: "Melody ",
    title: "Parent",
    image: "/assets/images/parent4.png",
  },
];

export const AdvancedTrackingSystem = [
  {
    id: 1,
    image: "/assets/images/AdvancedTrackinSys.png",
    title: "Advanced Tracking System",
    subtitle: "Unveiling the Power of Precision",
    para: "Unlocking the potential of technology, our cutting-edge tracking system, developed in partnership with Google, delivers unparalleled accuracy and unwavering reliability when it comes to tracking your child's school bus location.",
    buttonText: "Learn More",
    responsiveImage: "/assets/images/ATS.png",
  },
];

export const RealTimeUpdates = [
  {
    id: 1,
    image: "/assets/images/RealTimeUpdates.png",
    title: "Stay Informed, Stay Connected",
    subtitle: "Real-Time Automated Notifications and Alerts",
    para: "Say goodbye to uncertainty and hello to peace of mind. Our automated notification and alert system ensures that parents receive instant updates in real time, empowering them with timely information about their child's transportation status.",
    buttonText: "Learn More",
    responsiveImage: "/assets/images/StayConnected.png",
  },
];

export const YourNeeds = [
  {
    id: 1,
    image: "/assets/images/YourNeeds.png",
    title: "Tailored to Your Needs",
    subtitle: "Personalized Push Notifications at your Fingertips",
    para: "We understand that every parent has unique preferences. With our manual push notification system, you can customize alerts and notifications to suit your requirements. Stay effortlessly informed, receiving tailored updates about your child's transportation directly to your device.",
    buttonText: "Learn More",
    responsiveImage: "/assets/images/MyChildren.png",
  },
];

export const Communication = [
  {
    id: 1,
    image: "/assets/images/Communication.png",
    title: "Seamless Communication",
    subtitle: "Direct Contact through the Call Option",
    para: "In times when direct communication is crucial, our innovative app offers a convenient call option. Effortlessly connect with the bus driver, ensuring instant and direct contact whenever needed. Rest easy knowing that communication is just a tap away.",
    buttonText: "Learn More",
    responsiveImage: "/assets/images/SeamlessCommunication.png",
  },
];

export const AboutHeroSection = [
  {
    id: 1,
    image: "/assets/images/AboutHero.png",
    title: "About Us",
    para: "Hop on the Skoolbus and ensure the safety of your child. Follow the journey from start to finish, track the bus, find the current route, get alerts, fetch driver details and contact the driver directly. Monitor your child's journey without leaving your home or office and rest assured of your child's safety.",
  },
];

export const HowitWorksSection = [
  {
    id: 1,
    image: "/assets/images/HowitWorks.png",
    title: "Let's get started...",
    para: "Can Skoolbus keep my child safe while travelling to and from school?",
  },
];

export const HelpHeroSection = [
  {
    id: 1,
    image: "/assets/images/HelpHero.png",
    title: "Need Help?",
    para: "Have a question or need assistance? Fill out the form below, and our dedicated support team will promptly address your concerns. To We are here for you Tell us how we can help. Our support team is on hand to answer your questions.",
  },
];

export const TeamMembers = [
  {
    id: 1,
    name: "Iresh Bandara",
    title: "Director",
    image: "/assets/images/Iresh.png",
  },

  {
    id: 3,
    name: "Dinusha Kumari Herath",
    title: "Director",
    image: "/assets/images/Dinusha.png",
  },
  {
    id: 2,
    name: "Vinoth Kumar",
    title: "Director",
    image: "/assets/images/Vinoth 2.png",
  },
  {
    id: 4,
    name: "Sagara Sampath",
    title: "Tech Lead - SI & Cloud Services",
    image: "/assets/images/Sagara New.png",
  },
  {
    id: 5,
    name: "Kasun Meendeniya",
    title: "Manager - SI & Cloud Services",
    image: "/assets/images/Kasun.png",
  },
  // {
  //   id: 7,
  //   name: "Praveen Ranula",
  //   title: "UI/UX designer",
  //   image: "/assets/images/Praveen.png",
  // },
  // {
  //   id: 8,
  //   name: "Madawa Bandara",
  //   title: "Senior Business Analyst",
  //   image: "/assets/images/Madawa.png",
  // },
  // {
  //   id: 9,
  //   name: "Adhisha Gammanpila",
  //   title: "Product Manager",
  //   image: "/assets/images/Adisha.png",
  // },
  // {
  //   id: 6,
  //   name: "Ruwintha Fonseka",
  //   title: "Project Manager",
  //   image: "/assets/images/Ruwintha.png",
  // },
  {
    id: 6,
    name: "Ramanujan TV",
    title: "Head of Software Engineer",
    image: "/assets/images/Ram.png",
  },
  {
    id: 6,
    name: "Chanaka Nanayakkara",
    title: "Security Architect",
    image: "/assets/images/Chanaka.png",
  },
];

export const TeamMembersResponsive = [
  {
    id: 1,
    name: "Iresh Bandara",
    title: "Director",
    image: "/assets/images/Iresh.png",
  },

  {
    id: 3,
    name: "Dinusha Kumari Herath",
    title: "Director",
    image: "/assets/images/Dinusha.png",
  },
  {
    id: 2,
    name: "Vinoth Kumar",
    title: "Director",
    image: "/assets/images/Vinoth 2.png",
  },
  {
    id: 4,
    name: "Sagara Sampath",
    title: "Tech Lead - SI & Cloud Services",
    image: "/assets/images/Sagara New.png",
  },
  {
    id: 5,
    name: "Kasun Meendeniya",
    title: "Manager - SI & Cloud Services",
    image: "/assets/images/Kasun.png",
  },
  // {
  //   id: 7,
  //   name: "Praveen Ranula",
  //   title: "UI/UX designer",
  //   image: "/assets/images/Praveen.png",
  // },
  // {
  //   id: 8,
  //   name: "Madawa Bandara",
  //   title: "Senior Business Analyst",
  //   image: "/assets/images/Madawa.png",
  // },
  // {
  //   id: 9,
  //   name: "Adhisha Gammanpila",
  //   title: "Product Manager",
  //   image: "/assets/images/Adisha.png",
  // },
  // {
  //   id: 6,
  //   name: "Ruwintha Fonseka",
  //   title: "Project Manager",
  //   image: "/assets/images/Ruwintha.png",
  // },
  {
    id: 6,
    name: "Ramanujan TV",
    title: "Head of Software Engineer",
    image: "/assets/images/Ram.png",
  },
  {
    id: 6,
    name: "Chanaka Nanayakkara",
    title: "Security Architect",
    image: "/assets/images/Chanaka.png",
  },
];

export const InfoCount = [
  {
    id: 1,
    image: "/assets/icons/Parent.png",
    number: "250",
    subtitle: "+",
    name: "Parents",
  },
  {
    id: 1,
    image: "/assets/icons/Driver.png",
    number: "50",
    subtitle: "+",
    name: "Drivers",
  },
  {
    id: 1,
    image: "/assets/icons/Rides.png",
    number: "6",
    subtitle: "K +",
    name: "Successful Rides",
  },
  {
    id: 1,
    image: "/assets/icons/School.png",
    number: "75",
    subtitle: "+",
    name: "Schools",
  },
];

export const HowitWorksCardInfo = [
  {
    id: 1,
    icon: "/assets/icons/Icon1.png",
    image: "/assets/icons/HowItWorksIcon.svg",
    title: "Download",
    para: "Download Today and Stay Connected with SchoolBus",
  },
  {
    id: 2,
    icon: "/assets/icons/Icon1.png",
    image: "/assets/icons/HowItWorksIcon.svg",
    title: "Create",
    title2: "Account",
    para: "Create Your Account & Embrace Live School Bus Tracking",
  },
  {
    id: 3,
    icon: "/assets/icons/Icon1.png",
    image: "/assets/icons/HowItWorksIcon.svg",
    title: "Register",
    para: "Register now & enjoy effortless tracking",
  },
  {
    id: 4,
    icon: "/assets/icons/Icon1.png",
    image: "/assets/icons/HowItWorksIcon.svg",
    title: "Use GPS",
    para: "Use GPS to Stay in Sync with Your Child's School Bus",
  },
  {
    id: 5,
    icon: "/assets/icons/Icon1.png",
    image: "/assets/icons/HowItWorksIcon.svg",
    title: "Track Progress",
    para: "Track the Progress of Your Child's Every Step of the Way",
  },
];

export const HowitWorksSection1 = [
  {
    id: "App Security",
    image: "/assets/images/HowitWorksSection.svg",
    title: "App Security",
    para: "Can Skoolbus keep my child safe while travelling to and from school?",
  },
];

export const HowitWorksSection2 = [
  {
    id: "Privacy Policy",
    image: "/assets/images/HowitWorksSection.svg",
    title: "Privacy Policy",
    para: "eview our policy to understand how we collect, use, and share data and rest assured that we prioritize protecting your child’s personal information.",
  },
];

export const HowitWorksSection3 = [
  {
    id: "User Access",
    image: "/assets/images/HowitWorksSection.svg",
    title: "User Access",
    para: "Only authorized individuals like parents and guardians will have access to the Skoolbus information.",
  },
];

export const HowitWorksSection4 = [
  {
    id: "Parental Consent",
    image: "/assets/images/HowitWorksSection.svg",
    title: "Parental Consent",
    para: "Ensure you provide explicit consent for your child’s location data to be tracked and shared.",
  },
];

export const FAQsData = [
  {
    id: 1,
    question: "How does the tracking feature work?",
    answer:
      "The tracking feature on Skoolbus tracking app relies on GPS (Global Positioning System) technology, which enables the app to monitor the real-time location of the school bus.The school bus is equipped with a GPS receiver. When the school bus moves along its route, the GPS receiver continuously sends location data to a cloud-based server, providing real-time updates on the bus's movement.You can see the bus icon moving along the map in real-time, indicating its current position. The app integrates with digital maps, like Google Maps to display the bus's location visually.",
  },
  {
    id: 2,
    question: "Can my child's location information be shared with anyone else?",
    answer:
      "Yes, you are allowed to share the location of your child with another responsible party. A reputable school bus tracking app like Skoolbus always prioritizes the privacy and safety of children.Consent from parents or guardians is required before sharing a child's location information. Check our app's privacy policy to understand how we handle and share location information.",
  },
  {
    id: 3,
    question: "How often does the app update my child's location?",
    answer:
      "Skoolbus offers real-time tracking, which means that your child's location is updated as frequently as every few seconds to provide an accurate and live location that is visible on a map.",
  },
  {
    id: 4,
    question:
      "Can I set up alerts for when my child arrives at or leaves a specific location?",
    answer:
      "Yes, Skoolbus offers the option to set up alerts for when your child arrives at or leaves a specific location. Often referred to as alerts or 'location-based' alerts, it allows you to receive notifications whenever the school bus enters or exits a designated area. This feature can be particularly useful for knowing when your child arrives safely at school or returns home.",
  },
  {
    id: 5,
    question: "Is the app available for both iOS and Android devices?",
    answer:
      "Skoolbus is currently available for Android users, while work is in progress to make it compatible with iOS. We're actively developing the iOS version and look forward to bringing it to our iOS users in the near future.",
  },
  {
    id: 6,
    question: "How secure is the app?",
    answer:
      "Security is an important aspect in any tracking app and it’s vital when it comes to your child’s safety.Skoolbus uses encryption to protect the data being transmitted between the app, the server, and any devices involved. Encryption ensures that even if intercepted, the data remains unreadable without the appropriate decryption keys.By using strong authentication mechanisms, we ensure that only authorized users, such as parents or guardians can access the tracking information. Parents can only see their own child's information, and drivers have access only to relevant information.Refer our privacy policy which clearly outlines how users have control of their own information. If you have any concerns, please feel free to discuss them with us.",
  },
  {
    id: 7,
    question:
      "What is the cost of using the app and are there any additional fees?",
    answer:
      "Before committing to Skoolbus, it's a good idea to compare multiple options to ensure that you're getting the best features you need at a reasonable cost. We know how important your child's safety is, which is why the Skoolbus initial version is free to use for any concerned parent. How can we put a price tag on your child's safety?",
  },
  {
    id: 8,
    question: "What happens in case of emergencies or delays? ",
    answer:
      "Skoolbus sends immediate emergency alerts, keeping parents informed and prepared for any unforeseen events, prioritizing the safety of every child on board. ",
  },
  {
    id: 9,
    question: "Can I review past routes and arrival times? ",
    answer:
      "Yes, Skoolbus provides historical journey data, allowing parents to review past routes and arrival times for comprehensive planning and understanding of their child's travel patterns. ",
  },
];

export const DownloadButtons = [
  {
    id: 1,
    logo: "/assets/icons/AppleLogo.png",
    title: "Download on the",
    text: "App Store",
  },
  {
    id: 2,
    logo: "/assets/icons/GoogleLogo.png",
    title: "GET IT ON",
    text: "Google Play",
  },
];
