import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const MobilePhone = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const PhoneImages = [
    "/assets/images/ATS.png",
    "/assets/images/ATS.png",
    "/assets/images/StayConnected.png",
    "/assets/images/MyChildren.png",
    "/assets/images/SeamlessCommunication.png",
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const screenHeight = window.innerHeight;

      const cardHeights = [
        1 * screenHeight ,
        2 * screenHeight ,
        3 * screenHeight ,
        4 * screenHeight 
      ];

      let newImageIndex = 0;
      for (let i = 0; i < cardHeights.length; i++) {
        if (scrollPosition >= cardHeights[i]) {
          newImageIndex = i + 1;
        }
      }

      setCurrentImageIndex(newImageIndex);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="z-[100]">
      <motion.img
        key={PhoneImages[currentImageIndex]}
        src={PhoneImages[currentImageIndex]}
        alt="phone"
        className="h-[450px] "
        initial={{ opacity: 1 }} // Set initial opacity to 0
        animate={{ opacity: 1 }} // Animate opacity to 1
        transition={{ duration: 0.7 }} // Set the duration of the animation
      />
    </div>
  );
};

const MobilePhoneResponsive = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const PhoneImages = [
    "/assets/images/ATS.png",
    "/assets/images/ATS.png",
    "/assets/images/StayConnected.png",
    "/assets/images/MyChildren.png",
    "/assets/images/SeamlessCommunication.png",
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const screenHeight = window.innerHeight;

      const cardHeights = [
        1 * screenHeight + 1 * screenHeight,
        2 * screenHeight + 1.3 * screenHeight,
        3 * screenHeight + 1.5 * screenHeight,
        4 * screenHeight + 1.5 * screenHeight,
      ];

      let newImageIndex = 0;
      for (let i = 0; i < cardHeights.length; i++) {
        if (scrollPosition >= cardHeights[i]) {
          newImageIndex = i + 1;
        }
      }

      setCurrentImageIndex(newImageIndex);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="">
      <motion.img
        key={PhoneImages[currentImageIndex]}
        src={PhoneImages[currentImageIndex]}
        alt="phone"
        className="h-[500px]"
        initial={{ opacity: 0.5 }} // Set initial opacity to 0
        animate={{ opacity: 1 }} // Animate opacity to 1
        transition={{ duration: 0.7 }} // Set the duration of the animation
      />
    </div>
  );
};
const MobilePhoneMedium = ({ PhoneImages }) => {
  return (
    <div className="">
      <img src={PhoneImages} alt="phone" className="h-[600px]" />
    </div>
  );
};
export { MobilePhone, MobilePhoneResponsive, MobilePhoneMedium };
